import { copy } from 'angular';
import { DATE_FORMAT } from 'common/resource/module.constants';

export class IncidentPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $state, $stateParams, IncidentPageService, Toast, Utils, FlaggedIncidentDialog,
    DeploymentPageService, DeploymentDialog, IncidentDialog, Session, ShiftService
  ) {
    this._state = $state;
    this._stateParams = $stateParams;
    this._Toast = Toast;
    this._Utils = Utils;
    this._IncidentPageService = IncidentPageService;
    this._FlaggedIncidentDialog = FlaggedIncidentDialog;
    this._DeploymentPageService = DeploymentPageService;
    this._DeploymentDialog = DeploymentDialog;
    this._IncidentDialog = IncidentDialog;
    this._Session = Session;
    this._ShiftService = ShiftService;
  }

  $onInit () {
    this._incidentRelationData = {};
    this._deploymentRelationData = { shifts: [] };

    if (this.hasPermissionToEditIncident()) {
      this._loadRelationData();
    }

    this._IncidentPageService.getIncident(this._stateParams.id).then(incident => {
      this.incident = incident;
      this.incident.outcome = this._IncidentPageService.getIncidentOutcome(this.incident);
      this.incident.deployments.map(deployment => {
        deployment.outcome = this._DeploymentPageService.getDeploymentOutcomes(deployment);
        return deployment;
      });
      this.unresolvedFlagCount = this.incident.flagged_incidents.filter(i => !i.resolved).length;
      this._generateTimeline();
      this._generateBreadCrumbs();
      if (this.hasPermissionToEditIncident()) {
        this._loadShiftCrewData(this.incident.date.format(DATE_FORMAT));
      }
    });
  }

  hasPermissionToEditIncident () {
    const user = this._Session.user();
    if (!user || !user.group) return false;
    return [
      'system-administrators',
      'medical-staff',
      'medical-staff-prescribers',
      'ops-admins',
      'pilots',
      'call-handlers',
    ].indexOf(user.group.slug) > -1;
  }

  showIncidentDialog ($event) {
    const dialogParams = {
      $event: $event,
      item: copy(this.incident),
      editing: true,
      relationData: this._incidentRelationData,
      multiple: true,
    };

    this._IncidentDialog.show(dialogParams).then(incident => {
      this.incident = Object.assign({}, this.incident, incident);
      this._loadShiftCrewData(this.incident.date.format(DATE_FORMAT));
      this._generateTimeline();
      this._Toast.showSimple('Referral updated.');
    }).catch(incidentId => {
      if (typeof incidentId !== 'string' || !incidentId) {
        return;
      }
      this._state.go('requestLog');
      this._Toast.showSimple('Referral deleted.');
    });
  }

  showDeploymentDialog ($event) {
    const dialogParams = {
      $event: $event,
      editing: false,
      relationData: this._deploymentRelationData,
      locals: {
        incident: this.incident,
      },
    };

    this._DeploymentDialog.show(dialogParams).then(deployment => {
      this._Toast.showSimple('Deployment created.');
      this._state.go('deployments', { id: deployment.id });
    });
  }

  showFlagIncidentDialog ($event) {
    this._FlaggedIncidentDialog
      .show({ $event, incident: this.incident })
      .then(() => {
        this._Toast.showSimple('Referral flagged.');
        this.unresolvedFlagCount++;
      })
      .catch(e => {
        if (e != null && e.status) {
          this._Toast.showSimple('Error when flagging referral.');
        }
      });
  }

  _generateTimeline () {
    this.timelineEvents = this._IncidentPageService.generateTimeline(
      this.incident.times,
      this.incident.deployments
    );
  }

  _loadShiftCrewData (date) {
    const include = 'properties.vehicleCallsign,users.profile.image,users.profile.profession';
    this._ShiftService.getShiftsForDate(date, include).then(shifts => {
      this._deploymentRelationData.shifts = [...this._deploymentRelationData.shifts, ...shifts];
      this._ShiftService.getOvernightShiftsForDate(date).then(data => {
        const overnightShifts = data.map(shift => ({ ...shift, name: `${shift.name} (previous day)` }));
        this._deploymentRelationData.shifts = [...this._deploymentRelationData.shifts, ...overnightShifts];
      });
    });
  }

  _loadRelationData () {
    this._IncidentPageService.getRelationData().then(relationData => {
      Object.assign(this._incidentRelationData, relationData);
    });
    this._DeploymentPageService.getRelationData().then(relationData => {
      Object.assign(this._deploymentRelationData, relationData);
    });
  }

  _generateBreadCrumbs () {
    this.routes = [
      {
        title: 'Request Log',
        state: 'requestLog()',
      },
      {
        title: 'Referral',
      },
    ];
  }
}

export default {
  controller: IncidentPageController,
  templateUrl: 'pages/requestLog/components/incident-page/incident-page.tpl.html',
};
