export default class PrfPageService {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, BodyPartResource, DrugResource, InfusionResource,
    PatientTypeResource, PRFResource,
    CommunicationResource, EquipmentProblemResource, OrganisationalDelayResource, PhysiologicalDeteriorationResource,
    MedicationRouteResource, InfusionSolutionResource, InfectionStatusResource
  ) {
    this._q = $q;
    this._BodyPartResource = BodyPartResource;
    this._DrugResource = DrugResource;
    this._InfusionResource = InfusionResource;
    this._PatientTypeResource = PatientTypeResource;
    this._PRFResource = PRFResource;
    this._CommunicationResource = CommunicationResource;
    this._EquipmentProblemResource = EquipmentProblemResource;
    this._OrganisationalDelayResource = OrganisationalDelayResource;
    this._PhysiologicalDeteriorationResource = PhysiologicalDeteriorationResource;
    this._RouteResource = MedicationRouteResource;
    this._SolutionResource = InfusionSolutionResource;
    this._InfectionStatusResource = InfectionStatusResource;
  }

  getRelationData () {
    return this._q.all({
      bodyParts: this._BodyPartResource.index(),
      drugs: this._DrugResource.index(),
      infusions: this._InfusionResource.index(),
      patientTypes: this._PatientTypeResource.index(),
      communications: this._CommunicationResource.index(),
      equipmentProblems: this._EquipmentProblemResource.index(),
      organisationalDelays: this._OrganisationalDelayResource.index(),
      physiologicalDeteriorations: this._PhysiologicalDeteriorationResource.index(),
      routes: this._RouteResource.index(),
      solutions: this._SolutionResource.index(),
      measurementTypes: this._DrugResource.getMeasurementTypes(),
      infectionStatuses: this._InfectionStatusResource.index(),
    }).catch(console.log.bind(console));
  }

  getPrf (id) {
    return this._PRFResource.show(id, {
      include: [
        'approval', 'incident', 'stockTransactions', 'images', 'attachments',
        'deployment', 'deployment.users', 'deployment.vehicleCallsign',
        'surveys', 'surveys.observations', 'surveys.administeredDrugs',
        'surveys.assessedInjuries', 'surveys.assessedIllnesses', 'surveys.administeredInterventions',
        'deployment.deploymentStatuses', 'deployment.deploymentStatuses.deploymentStatus',
        'deployment.deploymentStatuses.destination',
        'clinicalIncident', 'handover', 'surveys.laboratoryFindings', 'surveys.administeredInfusions',
        'surveys.prescribedDrugs', 'surveys.prescribedInfusions', 'infectionStatuses',
      ],
    });
  }

  getTransportedDestination (deploymentStatuses) {
    const atHospital = deploymentStatuses.filter(item => !!item.deployment_status.is_at_hospital);
    if (atHospital.length) {
      const destination = atHospital[atHospital.length - 1].destination;
      return destination ? destination.name : '';
    }
    return null;
  }

  getPRFOutcome (transportedTo) {
    if (transportedTo !== '' && !transportedTo) {
      return 'Treated on scene';
    }

    let outcome = 'Transported to hospital';
    if (transportedTo !== '') {
      outcome += ' - ' + transportedTo;
    }
    return outcome;
  }

  getBreadcrumbRoutes (incidentId, deploymentId) {
    return [
      {
        title: 'Request Log',
        state: 'requestLog()',
      },
      {
        title: 'Referral',
        state: `incident({id: '${incidentId}'})`,
      },
      {
        title: 'Deployment',
        state: `deployments({id: '${deploymentId}'})`,
      },
      {
        title: 'PRF',
      },
    ];
  }
}
