class PrescribedDrugsController {
  $onInit () {
    this.clearSearch();
  }

  initSearch (index) {
    this.openIndex = index;
  }

  clearSearch () {
    this.openIndex = null;
    this.searchTerm = '';
  }

  filterOptions (index) {
    if (this.openIndex === index) return this.searchTerm;
  }

  getClasses (name, index) {
    return {
      'has-errors': this.prescribedDrugsForm[name + '_' + index].$invalid,
      't-no-padding': ~['drug', 'date'].indexOf(name),
    };
  }
}

export default {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    drugs: '<',
    onAddClick: '&',
    onRemoveClick: '&',
    onAddNewDrugClick: '&',
  },
  controller: PrescribedDrugsController,
  templateUrl: 'core/prf/components/prescribed-drugs/prescribed-drugs.tpl.html',
};
