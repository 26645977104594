import moment from 'moment';

const PAGE_SIZE = 25;

function formatItem (item) {
  item.referralNumber = item.incident.incident_number;
  item.flaggedBy = item.flagged_by.first_name + ' ' + item.flagged_by.last_name;
  item.flaggedOn = moment(item.flagged_on_time).format('DD-MM-YYYY');
  item.resolvedBy = item.resolved_by ? (item.resolved_by.first_name + ' ' + item.resolved_by.last_name) : '-';
  item.resolvedOn = item.resolved_on_time ? moment(item.resolved_on_time).format('DD-MM-YYYY') : '-';
  return item;
}

class FlaggedIncidentListController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state, FlaggedIncidentResource, ResolveIncidentDialog, Toast) {
    this.$state = $state;
    this.FlaggedIncidentResource = FlaggedIncidentResource;
    this.ResolveIncidentDialog = ResolveIncidentDialog;
    this.Toast = Toast;
  }

  $onInit () {
    this.TAB_FLAGGED = 'flagged';
    this.TAB_RESOLVED = 'resolved';
    this.resolved = false;

    this.tabs = [
      { id: this.TAB_FLAGGED, name: 'Flagged' },
      { id: this.TAB_RESOLVED, name: 'Resolved' },
    ];

    this.listFields = {
      referralNumber: 'Referral Number',
      flaggedBy: 'Flagged by',
      flaggedOn: 'Flagged on',
      resolvedBy: 'Resolved by',
      resolvedOn: 'Resolved on',
    };

    this.currentPage = 1;
    this.totalPages = 1;
    this.loadPage(1);
  }

  selectTab (tab) {
    if (this.tabs.map(item => item.id).indexOf(tab.id) === -1) {
      throw new Error('Tab name must be "flagged" or "resolved".');
    }
    this.resolved = tab.id === this.TAB_RESOLVED;
    this.loadPage(1);
  }

  previousPage () {
    if (this.currentPage === 1) return;
    this.currentPage--;
    this.loadPage(this.currentPage);
  }

  nextPage () {
    if (this.currentPage === this.totalPages) return;
    this.currentPage++;
    this.loadPage(this.currentPage);
  }

  loadPage (page) {
    this.currentPage = page;
    this._getItems(this.currentPage, PAGE_SIZE, this.resolved);
  }

  listButtonAction (event, item) {
    this.ResolveIncidentDialog
      .show({ item, $event: event })
      .then(() => {
        this.Toast.showSimple('Flagged incident resolved.');
        this.loadPage(this.currentPage);
      })
      .catch(e => {
        if (e != null && e.status) {
          this.Toast.showSimple('Error when resolving flagged incident.');
        }
      });
  }

  _getItems (page, pageSize, resolved = false) {
    this.items = [];
    this.FlaggedIncidentResource
      .index({
        include: 'incident,flaggedBy,resolvedBy',
        resolved: resolved ? 1 : 0,
        page,
        page_size: pageSize,
      })
      .then(items => {
        this.items = items.data.map(formatItem);
        this.totalPages = items.pagination.total_pages;
      });
  }
}

export default {
  controller: FlaggedIncidentListController,
  templateUrl: 'pages/admin/flagged-incidents/components/flagged-incident-list/flagged-incident-list.tpl.html',
};
