import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'common/resource/module.constants';

export default function parse (item) {
  const dateStr = moment(item.date).format(DATE_FORMAT) + ' ' + moment(item.time).format(TIME_FORMAT);

  const i = {
    ...item,
    time: dateStr,
    pupil_size_left: item.pupil_size_left != null ? parseInt(item.pupil_size_left, 10) : null,
    pupil_size_right: item.pupil_size_right != null ? parseInt(item.pupil_size_right, 10) : null,
    blood_pressure_1: item.blood_pressure != null ? item.blood_pressure.split('/')[0] : null,
    blood_pressure_2: item.blood_pressure != null ? item.blood_pressure.split('/')[1] : null,
    ie_ratio_1: item.ie_ratio != null ? item.ie_ratio.split(':')[0] : null,
    ie_ratio_2: item.ie_ratio != null ? item.ie_ratio.split(':')[1] : null,
    gcs: item.gcs || null,
  };

  delete i.blood_pressure;
  delete i.ie_ratio;

  return i;
};
