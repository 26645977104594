import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';

import AllDrugsController from './controllers/all-drugs.controller';
import BatchManagementController from './controllers/batch-management.controller';
import CreateStockCheckController from './controllers/create-stock-check.controller';
import DrugBatchesResource from './resources/drug-batches.resource';
import DrugBreakdownController from './controllers/drugs-breakdown.controller';
import DrugListController from './controllers/drug-list.controller';
import DrugResource from './resources/drug.resource';
import DrugStockResource from './resources/drug-stock.resource';
import DrugStockCheckResource from './resources/drug-stock-check.resource.js';
import DrugStockCheckItemResource from './resources/drug-stock-check-item.resource.js';
import DrugStockTransactionResource from './resources/drug-stock-transaction.resource';
import StockCheckController from './controllers/stock-check.controller';
import StockCheckListController from './controllers/stock-check-list.controller';
import TransactionHistoryController from './controllers/transaction-history.controller';
import InfusionResource from './resources/infusion.resource';
import InfusionListController from './controllers/infusion-list.controller';
import DrugDialog from './services/drug-dialog/drug-dialog.service';
import InfusionDialog from './services/infusion-dialog/infusion-dialog.service';

const module = angular
  .module('gnaas.pages.admin.drugs', [
    uiRouter,
    'ui.grid',
    'ui.grid.pagination',
    'ui.grid.autoResize',
    'ui.grid.resizeColumns',
  ])
  .config(ModuleConfig)
  .service('DrugResource', DrugResource)
  .service('DrugBatchesResource', DrugBatchesResource)
  .service('DrugStockResource', DrugStockResource)
  .service('DrugStockCheckResource', DrugStockCheckResource)
  .service('DrugStockCheckItemResource', DrugStockCheckItemResource)
  .service('DrugStockTransactionResource', DrugStockTransactionResource)
  .service('InfusionResource', InfusionResource)
  .service('DrugDialog', DrugDialog)
  .service('InfusionDialog', InfusionDialog)
  .controller('AllDrugsController', AllDrugsController)
  .controller('BatchManagementController', BatchManagementController)
  .controller('CreateStockCheckController', CreateStockCheckController)
  .controller('DrugBreakdownController', DrugBreakdownController)
  .controller('DrugListController', DrugListController)
  .controller('StockCheckController', StockCheckController)
  .controller('StockCheckListController', StockCheckListController)
  .controller('TransactionHistoryController', TransactionHistoryController)
  .controller('InfusionListController', InfusionListController);

export default module.name;
