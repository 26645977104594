import {
  getUniqueInfusionDetailTimestamps,
  getAdministeredInfusionMap,
} from 'core/prf/services/administered-infusion.service';
import { toDateTimeObject } from 'common/utils/services/dateTime';

class AdministeredInfusionsController {
  /**
   * @ngInject
   * @constructor
   */
  constructor (TimestampDialog) {
    this.TimestampDialog = TimestampDialog;
  }

  $onInit () {
    this.clearSearch();
  }

  $onChanges (changes) {
    if (changes.administeredInfusions) {
      this.infusionMap = getAdministeredInfusionMap(changes.administeredInfusions.currentValue);
    }
  }

  onInfusionChange () {
    this.infusionMap = getAdministeredInfusionMap(this.administeredInfusions);
  }

  initSearch (index) {
    this.openIndex = index;
  }

  clearSearch () {
    this.openIndex = null;
    this.searchTerm = '';
  }

  filterOptions (index) {
    if (this.openIndex === index) return this.searchTerm;
  }

  hasErrors (name, index, secondaryIndex = null) {
    const key = name + '_' + index + (secondaryIndex != null ? '_' + secondaryIndex : '');
    return this.administeredInfusionsForm[key] ? this.administeredInfusionsForm[key].$invalid : false;
  }

  showTimestampDialog (detail) {
    const uniqueTimestamps = getUniqueInfusionDetailTimestamps(this.administeredInfusions);
    this.TimestampDialog.show({
      item: {
        date: toDateTimeObject(detail.date),
        time: toDateTimeObject(detail.time),
      },
      locals: {
        validateAgainst: uniqueTimestamps,
        validationErrorMessage: 'Timestamp must be unique, this value already exists.',
      },
    }).then(timestamp => {
      detail.date = timestamp.date;
      detail.time = timestamp.time;
    });
  }

  getRateLimit (administeredInfusionId, key) {
    if (!this.infusionMap[administeredInfusionId] || !this.infusionMap[administeredInfusionId].prescription) {
      return null;
    }
    if (key === 'min') return this.infusionMap[administeredInfusionId].prescription.infusion.min_rate;
    const prescribedRate = this.infusionMap[administeredInfusionId].prescription.rate;
    const maxRate = this.infusionMap[administeredInfusionId].prescription.infusion.max_rate;
    return prescribedRate > maxRate ? prescribedRate : maxRate;
  }

  getSelectedName (prescription) {
    return prescription && prescription.infusion ? prescription.infusion.name : '-';
  }
}

export default {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    administeredInfusions: '<',
    administeredInfusionDetails: '<',
    prescriptions: '<',
    onAddInfusionClick: '&',
    onRemoveInfusionClick: '&',
    onAddDetailsClick: '&',
    onRemoveDetailsClick: '&',
  },
  controller: AdministeredInfusionsController,
  templateUrl: 'core/prf/components/administered-infusions/administered-infusions.tpl.html',
};
