import { toDateTimeString } from 'common/utils/services/dateTime';

export default function parse (item) {
  return {
    id: item.id,
    infusion_id: item.infusion.id,
    route_id: item.route.id,
    rate: item.rate,
    time: toDateTimeString(item.date, item.time),
  };
}
