const bpPattern = /^([01]?[0-9][0-9]?|2[0-4][0-9]|250)\/([01]?[0-9][0-9]?|2[0-4][0-9]|250)$/;
const ieRatioPattern = /^([1-4].?\d?|5|5.0):([1-4].?\d?|5|5.0)$/;
const singleDecimalPattern = /^\d{1,2}(.\d{1})?$/;
const doubleDecimalPattern = /^\d{1,2}(.\d{1,2})?$/;

const ecgOptions = [
  { label: '-', value: null },
  { label: 'SR', value: 'sr' },
  { label: 'SB', value: 'sb' },
  { label: 'ST', value: 'st' },
  { label: 'SVT', value: 'svt' },
  { label: 'VT', value: 'vt' },
  { label: 'AF', value: 'af' },
  { label: 'Arrest - asyst.', value: 'arrest-asystole' },
  { label: 'Arrest - PEA', value: 'arrest-pea' },
  { label: 'Arrest - VF', value: 'arrest-vf' },
  { label: 'Arrest - VT', value: 'arrest-vt' },
];

const calculateGcs = function (obs) {
  if (obs.gcse == null && obs.gcsv == null && obs.gcsm == null) {
    obs.gcs = null;
    return;
  }
  obs.gcs = 0;
  if (obs.gcse !== null && !isNaN(obs.gcse)) obs.gcs += parseInt(obs.gcse, 10);
  if (obs.gcsv !== null && !isNaN(obs.gcsv)) obs.gcs += parseInt(obs.gcsv, 10);
  if (obs.gcsm !== null && !isNaN(obs.gcsm)) obs.gcs += parseInt(obs.gcsm, 10);
};

const gcsFieldRequired = function (field, obs) {
  return ['gcse', 'gcsv', 'gcsm'].filter(i => {
    return i !== field.name && !isNaN(obs[i]) && obs[i] !== null;
  }).length > 0;
};

const disableDecimals = function ($event) {
  if ($event.key === '.') $event.preventDefault();
};

class ObservationsController {
  $onInit () {
    this.fields = [
      { label: 'Date', name: 'date', type: 'date', required: true },
      { label: 'Time', name: 'time', type: 'time', required: true },
      { label: 'ECG', name: 'ecg', type: 'predefined', options: ecgOptions },
      { label: 'HR', name: 'pulse', type: 'number', min: 0, max: 300, onKeyDown: disableDecimals },
      { label: 'B/P', name: 'blood_pressure', type: 'string', placeholder: '###/###', pattern: bpPattern },
      { label: 'MAP', name: 'map', type: 'number', min: 0, max: 150, onKeyDown: disableDecimals },
      { label: 'RR', name: 'respiratory_rate', type: 'number', min: 0, max: 100, onKeyDown: disableDecimals },
      { label: 'SPO2', name: 'spo2', type: 'number', min: 20, max: 100, onKeyDown: disableDecimals },
      { label: 'ETCO2', name: 'etco2', type: 'number', min: 0.0, max: 15.0, pattern: singleDecimalPattern },
      { label: 'Temp', name: 'temp', type: 'number', min: 20.0, max: 42.0, pattern: singleDecimalPattern },
      { label: 'FiO2', name: 'fio2', type: 'number', min: 0.21, max: 1.00, pattern: doubleDecimalPattern },
      { label: 'Ppeak', name: 'ppeak', type: 'number', min: 0, max: 50, onKeyDown: disableDecimals },
      { label: 'PEEP', name: 'peep', type: 'number', min: 0, max: 20, onKeyDown: disableDecimals },
      { label: 'I:E ratio', name: 'ie_ratio', type: 'text', placeholder: '#.#:#.#', pattern: ieRatioPattern },
      { label: 'GCSE', name: 'gcse', type: 'number', min: 1, max: 4, onKeyDown: disableDecimals,
        onChange: calculateGcs, required: gcsFieldRequired },
      { label: 'GCSV', name: 'gcsv', type: 'number', min: 1, max: 5, onKeyDown: disableDecimals,
        onChange: calculateGcs, required: gcsFieldRequired },
      { label: 'GCSM', name: 'gcsm', type: 'number', min: 1, max: 6, onKeyDown: disableDecimals,
        onChange: calculateGcs, required: gcsFieldRequired },
      { label: 'GCS', name: 'gcs', type: 'number', disabled: true },
      { label: 'Pupil Left', name: 'pupil_size_left', type: 'number', min: -6, max: 6, onKeyDown: disableDecimals },
      { label: 'Pupil Right', name: 'pupil_size_right', type: 'number', min: -6, max: 6, onKeyDown: disableDecimals },
      { label: 'Output (mls)', name: 'output_mls', type: 'number', min: 0, max: 5000, onKeyDown: disableDecimals },
      { label: 'CVP', name: 'cvp', type: 'number', min: 0, max: Number.MAX_SAFE_INTEGER, onKeyDown: disableDecimals },
      { label: 'ABP Systolic', name: 'abp_systolic', type: 'number',
        min: 0, max: Number.MAX_SAFE_INTEGER, onKeyDown: disableDecimals },
      { label: 'ABP Diastolic', name: 'abp_diastolic', type: 'number',
        min: 0, max: Number.MAX_SAFE_INTEGER, onKeyDown: disableDecimals },
      { label: 'ABP Mean', name: 'abp_mean', type: 'number',
        min: 0, max: Number.MAX_SAFE_INTEGER, onKeyDown: disableDecimals },
    ];
  }

  isRequired (field, obs) {
    return typeof field.required === 'function' ? field.required(field, obs) : field.required;
  }
}

export default {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    onAddClick: '&',
    onRemoveClick: '&',
  },
  controller: ObservationsController,
  templateUrl: 'core/prf/components/observations/observations.tpl.html',
};
