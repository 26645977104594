import angular from 'angular';

function EnvConfig ($compileProvider) {
  'ngInject';
  $compileProvider.debugInfoEnabled(false);
}

angular.module('gnaas.app')
  .config(EnvConfig)
  .constant('APP_ENV', 'demo')
  .constant('APP_ORG', window.env['APP_ORG'] || 'CTS Demo')
  .constant('APP_ORG_SHORT', window.env['APP_ORG_SHORT'] || 'CTS Demo')
  .constant('API_URL', window.env['API_URL'] || '')
  .constant('BING_MAPS_KEY', window.env['BING_MAPS_KEY'] || '')
  .constant('GOOGLE_GEOCODING_KEY', window.env['GOOGLE_GEOCODING_KEY'] || '');
