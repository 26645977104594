import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

function generateStockLocationProperties (stockLocations) {
  return stockLocations.map(location => ({ id: uuid.v4(), stock_location: location }));
}

function generateMissingStockLocationProperties (stockLocationProps, stockLocations) {
  const existingStockLocationPropIds = stockLocationProps.map(item => item.stock_location.id);
  const pendingStockLocations = stockLocations.filter(i => existingStockLocationPropIds.indexOf(i.id) === -1);
  const generatedStockLocationProps = generateStockLocationProperties(pendingStockLocations);
  return [...stockLocationProps, ...generatedStockLocationProps];
}

function generateProductCodes (suppliers) {
  return suppliers.map(supplier => ({ id: uuid.v4(), supplier, product_code: null }));
}

function generateMissingProductCodes (productCodes, suppliers) {
  const existingProductCodeIds = productCodes.map(i => i.supplier.id);
  const pendingProductCodes = suppliers.filter(i => existingProductCodeIds.indexOf(i.id) === -1);
  const generatedProductCodes = generateProductCodes(pendingProductCodes);
  return [...productCodes, ...generatedProductCodes];
}

export default class CreateDrugController extends ResourceDialogController {
  init () {
    super.init();
    this.DrugResource = this.$injector.get('DrugResource');
    this.ID = uuid.v4();

    if (!this.item) {
      this.item = { exclude_from_stock: true };
    }

    if (!this.excludeFromStock) {
      if (!this.item.stock_location_properties || !this.item.stock_location_properties.length) {
        this.item.stock_location_properties = generateStockLocationProperties(this.relationData.stockLocations);
      } else {
        this.item.stock_location_properties = generateMissingStockLocationProperties(
          this.item.stock_location_properties,
          this.relationData.stockLocations
        );
      }

      if (!this.item.product_codes || !this.item.product_codes.length) {
        this.item.product_codes = generateProductCodes(this.relationData.suppliers);
      } else {
        this.item.product_codes = generateMissingProductCodes(this.item.product_codes, this.relationData.suppliers);
      }
    }
  }

  delete (id) {
    this.Dialog.confirm().then(() => {
      this.DrugResource.destroy(id).then(() => {
        this.Dialog.cancel(id);
      });
    });
  };

  create (data) {
    data.id = this.ID;
    if (data.exclude_from_stock) delete data.stock_location_properties;

    this.DrugResource.create(data).then(() => {
      this.Dialog.hide(data);
    }).catch(err => {
      if (err.status) {
        this.Toast.showSimple('Error: could not create drug.');
      }
    });
  };

  update (data) {
    if (data.exclude_from_stock) delete data.stock_location_properties;

    this.DrugResource
      .update(data.id, data, { include: 'productCodes.supplier,stockLocationProperties' })
      .then(drug => { this.Dialog.hide(drug); })
      .catch(err => {
        if (err.status) {
          this.Toast.showSimple('Error: could not update drug.');
        }
      });
  };
}
